<template>
  <div class="pages">
    <div class="page" v-if="indexs == 1">
      <div class="inside-nav">
        <el-tree
          :data="treeData"
          :props="defaultProps"
          :highlight-current="true"
          accordion
          @node-click="handleNodeClick"
          node-key="cid"
        >
        </el-tree>
      </div>

      <div class="task_box">
        <!-- menu -->
        <div class="elmenu">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1">能耗时比</el-menu-item>
            <el-menu-item index="2">能耗类比</el-menu-item>
          </el-menu>
        </div>
        <!-- 顶部按钮 -->
        <div class="oper_box">
          <el-radio-group v-model="from.type" @input="inputRradio">
            <el-radio :label="1">同比</el-radio>
            <el-radio :label="2">环比</el-radio>
            <el-radio :label="3">自定义</el-radio>
          </el-radio-group>
          <div class="s_item">
            <div class="s_lable">基准日期：</div>
            <el-date-picker
              v-model="from.screen"
              type="date"
              @change="jitimeChange"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="s_item" v-if="from.type == '3'">
            <div class="s_lable">对比日期：</div>
            <el-date-picker
              v-model="from.contrast_time"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
        <!-- select 筛选 -->
        <div class="select_box">
          <div class="s_item">
            <div class="s_lable">数据周期：</div>
            <el-select
              v-model="selectValue"
              placeholder="请选择设备名称"
              @change="changeTime"
            >
              <el-option
                v-for="item in numoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
              >
              </el-option>
            </el-select>
          </div>
          <div class="btns" @click="submit">查询</div>
        </div>
        <div class="charts">
          <div class="onecharts"></div>
          <div class="pie">
            <div class="twocharts"></div>
            <div class="contable" v-if="contrastList.length != 0">
              <div class="one_top o_t">
                <div class="boxs_title">
                  <div>序号</div>
                  <div>设备名称</div>
                  <div>设备编号</div>
                  <div>用电量</div>
                </div>
                <div class="boxs">
                  <div
                    class="lists"
                    :style="{ top: listTop + 'px' }"
                    @mouseenter="Stop()"
                    @mouseleave="Up()"
                  >
                    <div
                      class="il"
                      v-for="(item, index) in contrastList"
                      :key="index"
                    >
                      <div class="date">{{ index + 1 }}</div>
                      <div class="date">{{ item.name }}</div>
                      <div class="date">{{ item.equip_no }}</div>
                      <div class="date">{{ item.electricity }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page" v-if="indexs == 2">
      <div class="inside-nav">
        <!-- <el-tree
          :data="treeDataTwo"
          ref="tree"
          show-checkbox
          node-key="id"
          :props="defaultPropsTwo"
          @node-change="handleCheckChangetwo"
        >
        </el-tree> -->
        <el-tree
          :data="treeDataTwo"
          ref="trees"
          show-checkbox
          node-key="id"
          :props="defaultProps"
          @check-change="handleCheckChangetwo"
        >
        </el-tree>
      </div>

      <div class="task_box">
        <!-- menu -->
        <div class="elmenu">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1">能耗时比</el-menu-item>
            <el-menu-item index="2">能耗类比</el-menu-item>
          </el-menu>
        </div>
        <!-- 顶部按钮 -->
        <div class="oper_box">
          <div class="s_item s_item_s">
            <div class="s_lable">日期选择：</div>
            <el-radio-group v-model="leiFrom.radio">
              <el-radio :label="1">昨日</el-radio>
              <el-radio :label="2">上周</el-radio>
              <el-radio :label="3">上月</el-radio>
              <el-radio :label="4">上季度</el-radio>
              <el-radio :label="5">上年</el-radio>
              <el-radio :label="6">自定义</el-radio>
            </el-radio-group>
          </div>

          <div class="btns" @click="look">查询</div>
        </div>
        <!-- select 筛选 -->
        <div class="oper_box">
          <div class="s_item s_item_s" v-if="leiFrom.radio == '6'">
            <div class="s_lable">数据周期：</div>
            <el-select
              v-model="selectValue"
              @change="LeichangeTime"
              placeholder="请选择设备名称"
            >
              <el-option
                v-for="item in leioptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="s_item s_item_s" v-if="leiFrom.radio == '6'">
            <div class="s_lable">开始日期：</div>
            <el-date-picker
              v-model="leiFrom.screen"
              type="date"
              placeholder="选择日期"
              @change="timeChange(leiFrom.screen)"
            >
            </el-date-picker>
          </div>
        </div>

        <!-- <div class="select_box index_box">
          <div>
            <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
          </div>

        </div> -->
        <div class="chartss">
          <div class="threecharts"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      numoptions: [
        {
          value: "days",
          label: "日",
        },
        {
          value: "month",
          label: "月",
        },
        {
          value: "year",
          label: "年",
        },
      ],
      selectValue: "",
      // 副 菜单
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      radio: 1,
      time: "",
      activeIndex: "1",
      indexs: 1,
      dynamicTags: ["标签一", "标签二", "标签三"],
      // 树状图选择
      treeDataTwo: [],
      defaultPropsTwo: {
        children: "children",
        label: "title",
      },
      listTop: 0,
      from: {
        organize_id: "",
        type: 1,
        screen: "", // 基准时间
        contrast_time: "", //对比时间
        cycle: "",
      },
      contrastList: [],
      leioptions: [
        {
          value: "days",
          label: "日",
        },
        {
          value: "month",
          label: "月",
        },
        {
          value: "year",
          label: "年",
        },
      ],
      leiFrom: {
        device_id: "", //设备
        cycle: "", // 年月日
        screen: "",
        date: "",
        radio: 1,
      },
    };
  },
  mounted() {
    this.getzuzhiList();
    this.getzuzhiListTwo();
  },
  methods: {
    // 能耗类比
    async look() {
      if (this.leiFrom.radio == 1) {
        this.leiFrom.date = "yesterday";
      } else if (this.leiFrom.radio == 2) {
        this.leiFrom.date = "lastweek";
      } else if (this.leiFrom.radio == 3) {
        this.leiFrom.date = "lastmonth";
      } else if (this.leiFrom.radio == 4) {
        this.leiFrom.date = "lastquarter";
      } else if (this.leiFrom.radio == 5) {
        this.leiFrom.date = "lastyear";
      } else if (this.leiFrom.radio == 6) {
        this.leiFrom.date = "custom";
      }
      const res = await this.$http.post(
        "device/electric_contrast_class_than",
        this.leiFrom
      );
      this.threeChart(res.data);
    },
    // 时间选择
    timeChange(val) {
      console.log(val, "valvalval");
      const newDate =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
      this.leiFrom.screen = newDate;
    },
    // 周期下拉
    LeichangeTime(e) {
      this.leiFrom.cycle = e;
    },
    // 组织 获取
    async getzuzhiListTwo() {
      const res = await this.$http.post("Organize/left");
      this.treeDataTwo = res.data;
    },

    //获取选中的点
    handleCheckChangetwo() {
      console.log(2222222222);
      let res = this.$refs.trees.getCheckedNodes();
      let arr = [];
      res.forEach((item) => {
        if (!item.children) {
          arr.push(item.id);
        }
      });
      this.leiFrom.device_id = arr.join(",");
    },
    // 能耗类比

    async submit() {
      const res = await this.$http.post("device/time_ratio", this.from);
      if (res.code !== 1) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.oneChart(res.data);

      const restwo = await this.$http.post(
        "device/contrast_piechart",
        this.from
      );
      if (restwo.code !== 1) return this.$message.error(res.msg);
      this.twoChart(restwo.data);

      const resthree = await this.$http.post("device/contrast_device", {
        organize_id: this.from.organize_id,
        screen: this.from.screen,
        cycle: this.from.cycle,
        limit: 5,
      });
      if (resthree.code !== 1) return this.$message.error(res.msg);
      this.contrastList = resthree.data.data;
    },
    // 同比 环比 自定义
    inputRradio(e) {
      this.from.type = e;
    },
    // 1组织 获取
    async getzuzhiList() {
      console.log(444444);
      const res = await this.$http.post("Organize");
      this.treeData = res.data;
    },
    handleNodeClick(data) {
      // 组织
      console.log(data);
      this.from.organize_id = data.id;
    },
    // 周期下拉
    changeTime(e) {
      this.from.cycle = e;
    },
    // 时间选择
    jitimeChange(val) {
      const newDate =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
      this.from.screen = newDate;
    },
    //  1获取选中的点
    onehandleCheck(data) {
      // let res = this.$refs.tree.getCheckedNodes()
      // let arr = []
      // res.forEach((item) => {
      //   arr.push(item.id)
      // })
      // this.device_id = arr.join(',');
      console.log(data, "ddd");
    },

    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    // 标签删除
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    // 能耗时比 类比切换
    handleSelect(key, keyPath) {
      console.log(key, keyPath, "key, keyPath");
      this.indexs = key;
      if (key == 1) {
        this.$nextTick(() => {
          this.getzuzhiList();

          this.oneChart();
          this.twoChart();
        });
      } else {
        this.$nextTick(() => {
          this.getzuzhiListTwo();
          this.threeChart();
        });
      }
    },

    qiCharts() {
      this.$nextTick(() => {
        this.oneChart();
        this.twoChart();
        this.threeChart();
      });
    },
    oneChart(data) {
      console.log(111);
      var myChart = echarts.init(document.querySelector(".onecharts"));
      var oneOption = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          // data: ["总电量", "当月总电量", "当日总电量"],
          textStyle: {
            color: "#fff", // 文本颜色
          },
        },

        calculable: true,
        xAxis: [
          {
            type: "category",
            axisLabel: {
              color: "#fff", // 文本颜色
            },
            show: true, //隐藏或显示

            // prettier-ignore
            data: data.title,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "Kw/h",
            axisLabel: {
              color: "#fff", // 文本颜色
            },
            splitLine: {
              //网格线
              lineStyle: {
                type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
              },
              show: true, //隐藏或显示
            },
          },
        ],

        series: [
          {
            data: data.one.value,
            type: "line",
            name: data.one.name,
            smooth: true,
            color: ["#287CE8"],
          },
          {
            name: data.two.name,
            data: data.two.value,
            type: "line",
            smooth: true,
            color: ["#F2C347"],
          },
        ],
      };
      myChart.setOption(oneOption);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    twoChart(data) {
      console.log(data, "ffff");
      var myChart = echarts.init(document.querySelector(".twocharts"));
      var twooption = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
          textStyle: {
            color: "#fff", // 文本颜色
          },
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myChart.setOption(twooption);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    threeChart(data) {
      var series = []; //定义一个数组变量用于存放配置

      for (var i = 0; i < data.length; i++) {
        series.push({
          name: data[i].title,
          type: "bar",
          stack: "总量",
          barWidth: 40,
          data: data[i].value,
        });
      }
      //  var xAxis=[];    //定义一个数组变量用于存放配置

      //  for(var i = 0;i<data.length;i++){
      //    xAxis.push({
      //          type: "category",
      //         data:  data[0].date,
      //         axisPointer: {
      //           type: "shadow",
      //         },
      //     });
      // }

      // console.log(xAxis,'xAxisxAxis');
      var myChart = echarts.init(document.querySelector(".threecharts"));
      var threeoption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          // data: [],
          textStyle: {
            color: "#fff", // 文本颜色
          },
        },
        xAxis: [
          {
            type: "category",
            data: data[0].date,
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "Kw/h",
            axisLabel: {
              color: "#fff", // 文本颜色
            },
          },
        ],
        series: series,
      };
      myChart.setOption(threeoption);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: #68d8fe;
  color: #fff;
}
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

// 副菜单
.inside-nav {
  width: 200px;
  height: calc(100% - 40px);
  margin-top: 20px;
  box-sizing: border-box;
  border: #61cdf1 2px solid;
  background-color: #1b284d;
  overflow-y: auto;
}
/* 左侧二级菜单滚动条 设置滚动条 */
.inside-nav::-webkit-scrollbar {
  width: 4px;
}
.inside-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #68d8fe;
}
.inside-nav::-webkit-scrollbar-track {
  border-radius: 0;
  background: #1b284d;
}
/* 设置滚动条  end */

.el-tree {
  background-color: #1b284d;
  color: #ffffff;
}
::v-deep .el-tree-node__label {
  font-size: 14px;
}
::v-deep .el-tree-node__content {
  height: 30px;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #1b284d;
  color: #68d8fe;
}
::v-deep .el-tree-node__content:hover,
::v-deep .el-upload-list__item:hover {
  background-color: #1b284d;
  color: #68d8fe;
}

// 表格
.task_box {
  width: calc(100% - 220px);
  ::v-deep .el-input__inner {
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #e5e5e5 !important;
    border: none;
    background: rgba(0, 0, 0, 0) !important;
    border: 1px solid #61cdf1 !important;
  }
  // charts
  .onecharts {
    width: 100%;
    height: 360px;
  }
  .threecharts {
    width: 100%;
    height: 600px;
  }
  .pie {
    display: flex;
    align-items: center;
    padding-left: 120px;

    .twocharts {
      width: 40%;
      height: 330px;
    }
    .contable {
      width: 627px;
      height: 273px;
      background: url("../../assets/images/contrastBg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .boxs_title {
        width: 565px;
        height: 34px;
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1ac9ff;
        line-height: 44px;
        margin: auto;
      }
      .boxs_title div {
        width: 25%;
        /* margin-left: 20px; */
        text-align: center;
        /* margin-right: .125rem; */
      }
      .boxs {
        height: 240px;
        overflow: hidden;
        position: relative;
      }
      .lists {
        position: absolute;
        /* left: -12px; */
      }
      .il {
        width: 565px;
        height: 34px;
        margin: 13px auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 14px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
        cursor: pointer;
      }
      .il:hover {
        /* width: 704px; */

        height: 34px;
        /* background: url(../img/hoverList.png) no-repeat; */
      }
      .il:nth-child(2n) {
        background: #213058;
        opacity: 0.34;
      }
      .date {
        width: 25%;
        text-align: center;
        font-size: 14px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #fefefe;
      }
      .people {
        width: 20%;
      }
      .il .time {
        /* width: 33%; */
        width: 20%;

        font-size: 0.175rem;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #1ac9ff;
      }
      .il .state {
        /* width: 33%; */

        font-size: 14px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #cccc;
      }
    }
  }
  // 顶部按钮
  .elmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-menu.el-menu--horizontal {
      border: 1px solid #61cdf1 !important;
    }
    .el-menu.el-menu--horizontal {
      border: none !important;
    }
    .el-menu {
      background-color: rgba(0, 0, 0, 0) !important;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-menu--horizontal > .el-menu-item {
      color: #fff !important;
    }
    .el-menu-item.is-active {
      color: #68d8fe !important;
    }

    .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    .el-menu-item {
      border-left: 0px;
      margin-right: 20px;
    }
  }
  .oper_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    margin-bottom: 20px;

    .btns {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #ffffff;
      margin-left: 25px;
      background-color: #68d8fe;
      border-radius: 5px;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      cursor: pointer;
      .el-icon {
        font-size: 20px;
        margin-right: 5px;
      }
    }
    .s_item {
      display: flex;
      align-items: center;
      margin-left: 80px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
  }

  // select 筛选
  .index_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-tag {
      margin-right: 10px;
    }
  }
  .select_box {
    display: flex;
    align-items: center;
    padding: 15px;
    .s_item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
    .btns {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #68d8fe;
      font-size: 16px;
      color: #ffffff;
      width: 80px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
.s_item_s {
  margin-right: 30px;
  margin-left: 0px !important;
}
</style>
